<!-- eslint-disable max-len -->
<template>
  <div class="signature-generator">
    <div class="preview" id="preview">
      <table style="direction: ltr; border-radius: 0; moz-border-radius:0; khtml-border-radius:0; o-border-radius:0; webkit-border-radius:0; ms-border-radius:0;" class="main_html">
        <tr>
          <td>
            <table cellpadding="0" cellspacing="0" class="ws-tpl" style="font-family: Arial; line-height: 1.15; padding-bottom: 10px; color: #000;">
              <tr>
                <td style="vertical-align: top; padding-right: 16px;">
                  <table cellpadding="0" cellspacing="0" class="ws-tpl-photo" style="width:65px;">
                    <tr>
                      <td align="center"> <img moz-do-not-send="true" alt="image" :src="image" height="65" width="65" style=" width:65px; vertical-align: initial; border-radius:50%; moz-border-radius:50%; khtml-border-radius:50%; o-border-radius:50%; webkit-border-radius:50%; ms-border-radius: 50%; display: block; height:65px;"/> </td>
                    </tr>
                  </table>
                </td>
                <td valign="top" style="vertical-align: top; line-height: 0%;">
                  <table cellpadding="0" cellspacing="0" style="line-height: 1.2;">
                    <tr>
                      <td valign="top" style="vertical-align: top; padding-right: 45px; line-height: 1.2;"> <span class="ws-tpl-name" style="font-family: Arial;  font-size: 16px;  color: #45668E;  text-transform: initial;  font-weight: bold;" data-acs="name"> {{name}} </span><br/> <span class="ws-tpl-title" style="font-size: 12px;  letter-spacing: 0px;  font-family: Arial;  text-transform: initial;  font-weight: normal;  color: #333;" data-acs="title"> {{role}} </span><br/> <span class="ws-tpl-company" style="font-size: 12px;  letter-spacing: 0px;  font-family: Arial;  text-transform: initial;  font-weight: bold;  color: #444;" data-acs="company"> Novo Peak GmbH </span> </td>
                      <td valign="top" style="vertical-align: top; line-height: 0%;">
                        <table cellpadding="0" cellspacing="0" style="font-size: 12px; line-height: 1.2;">
                          <tr>
                            <td style="line-height:0;">
                              <table cellpadding="0" cellspacing="0" >
                                <tr v-if="show_mobile">
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-phone" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="Telefon" src="https://novo-peak.it/signatur/mobile.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <a :href="`tel:${mobile}`" target="_blank" style="color-scheme: light only; text-decoration: none; font-size: 12px;font-family: Arial"> <span data-acs="phone" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> {{mobile}} </span> </a> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-phone" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="Telefon" src="https://novo-peak.it/signatur/phone.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <a :href="`tel:${tel}`" target="_blank" style="color-scheme: light only; text-decoration: none; font-size: 12px;font-family: Arial"> <span data-acs="phone" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> {{tel}} </span> </a> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-website" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="Website" src="https://novo-peak.it/signatur/website.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <a href="https://www.novo-peak.com" target="_blank" style="color-scheme: light only; text-decoration: none; font-size: 12px;font-family: Arial"> <span data-acs="website" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> www.novo-peak.com </span> </a> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-email" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="E-Mail" src="https://novo-peak.it/signatur/envelope.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <a :href="`mailto:${email}`" target="_blank" style="color-scheme: light only; text-decoration: none; font-size: 12px;font-family: Arial"> <span data-acs="email" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> {{email}} </span> </a> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-address" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="Map" src="https://novo-peak.it/signatur/marker.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <a href="https://maps.google.com/?q=Wilhelminenstra%C3%9Fe%206%2C%20D-90461%20N%C3%BCrnberg" target="_blank" style="color-scheme: light only; text-decoration: none; font-size: 12px;font-family: Arial"> <span data-acs="address" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> Wilhelminenstraße 6, D-90461 Nürnberg </span> </a> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td style="line-height: 0%; padding-bottom: 6px;">
                                    <table class="ws-tpl-registergericht" cellpadding="0" cellspacing="0" style="line-height: 14px; font-size: 12px;font-family: Arial;">
                                      <tr>
                                        <td style=" color-scheme: light only; font-family: Arial; font-weight:bold; font-size: 12px; color: #45668E ;"> <img moz-do-not-send="true" alt="Info" src="https://novo-peak.it/signatur/chevron-right.jpeg" style="vertical-align: -2px ; line-height: 1.2;"> </td>
                                        <td style="width: 5px; font-size: 1pt; line-height: 0;" width="5">&nbsp;</td>
                                        <td style="color-scheme: light only; font-family: Arial;font-size: 12px;"> <span data-acs="registergericht" style="line-height: 1.2; color-scheme: light only; color: #212121; font-family: Arial; white-space: nowrap; font-size: 12px;"> Registergericht Nürnberg HRB 38858 </span> </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <hr>
    <div class="editor">
      <div>
        <label>Name</label>
        <input v-model="name">
      </div>
      <div>
        <label>Funktion / Rolle</label>
        <input v-model="role">
      </div>
      <div>
        <label>Mobil <input type="checkbox" id="checkbox" v-model="show_mobile"></label>
        <input v-model="mobile">
      </div>
      <div>
        <label>Telefon</label>
        <input v-model="tel">
      </div>
      <div>
        <label>E-Mail</label>
        <input v-model="email">
      </div>
      <div>
        <label>Bild</label>
        <input type="file" name="image" @change="changeFile" />
      </div>
      <div>
        <button @click="copyHtml">HTML kopieren</button>
      </div>
    </div>
  </div>
</template>

<!-- eslint-disable arrow-parens -->
<!-- eslint-disable no-console -->
<!-- eslint-disable no-return-assign -->
<!-- eslint-disable prefer-template -->
<!-- eslint-disable no-alert -->
<script>
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      name: 'Vorname Nachname',
      tel: '+49 911 88080 554',
      mobile: '+49 173 336699',
      show_mobile: true,
      role: 'Funktion / Rolle',
      email: 'vorname.nachname@novo-peak.com',
      image: 'https://picsum.photos/65/65',
      file: null,
    };
  },
  methods: {
    changeFile(ev) {
      const reader = new FileReader();
      const file = ev.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.file = e.target.result;
        this.uploadImage();
      };
    },
    uploadImage() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: this.name, img: this.file }),
      };
      fetch('https://novo-peak.it/signatur/_upl.php', requestOptions)
        .then(response => response.json())
        .then(data => (this.image = 'https://novo-peak.it/signatur/' + data.filename));
    },
    copyHtml() {
      const html = document.getElementById('preview');
      navigator.clipboard.writeText(html.innerHTML)
        .then(() => {
          alert('HTML kopiert.');
        })
        .catch(err => {
          alert('HTML konnte nicht kopiert werden.' + err);
        });
    },
  },
};
</script>

<style>
  .preview {
    height: 140px;
  }

  .editor {
    width: 50%;
    max-width: 500px;
    margin: 0;
  }

  .editor label {
    width: 200px;
    text-align: left;
    display: block;
  }

  .editor label input {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: auto;
  }

  .editor input {
    display: block;
    font-size: 100%;
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
  }

  .editor button {
    display: block;
    margin-bottom: 10px;
  }
</style>
